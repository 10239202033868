import { Component, HostListener, OnInit } from '@angular/core';
import { UserService } from '../core/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { appVariables } from '../app.constants';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material';
import { ApplicationInsightsService } from '../shared/application-insights.service';
import { IUser } from "../core/interfaces/app.interface";

@Component({
  selector: 'app-root',
  template: `
    <app-switch-groups *ngIf="loadSwitchUserGroup" (userGroup)="authenticateUser($event.isTM, $event.email)"></app-switch-groups>
    <app-spinner *ngIf="isBusy" [isbusy]="isBusy"></app-spinner>
    <router-outlet *ngIf="!loadSwitchUserGroup"></router-outlet>`
})
export class AppComponent implements OnInit {

  loadSwitchUserGroup = false;
  isBusy = false; 
  isScheduledMaintainance = false;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.closeAlert();
  }

  constructor(public adalService: MsAdalAngular6Service, private authService: AuthService, private userService: UserService,
              private router: Router, private route: ActivatedRoute, private snackBar: MatSnackBar, private applicationInsightsService: ApplicationInsightsService) {
                this.scheduledMaintainance(false);
  }

  ngOnInit(): void {
    //this.scheduledMaintainance(true);
    if (_.includes(window.location.hash, 'shipto')) {
      this.route.paramMap.subscribe(params => {
        const shipToSearchValue = params.get('shipto');
        const countrySearchValue = this.route.snapshot.queryParamMap.get('country');
        if(shipToSearchValue && countrySearchValue) {
          sessionStorage.setItem(appVariables.guids.paramGUID, shipToSearchValue);
          sessionStorage.setItem(appVariables.guids.searchGUID, countrySearchValue);
          this.router.navigate(['']);
        }
      });
    }   

    if (window === window.parent) {
      if (this.adalService.isAuthenticated && !this.userService.isAuthenticatingUser) {
        this.applicationInsightsService.setUserId(this.adalService.LoggedInUserEmail);
        if (sessionStorage.getItem(appVariables.guids.paramGUID) && sessionStorage.getItem(appVariables.guids.searchGUID)) {
          this.authenticateUser(false);
        } else if (_.includes(appVariables.adminDevGroup, this.adalService.LoggedInUserEmail.toLowerCase())) {
          this.router.navigate(['']);
          this.loadSwitchUserGroup = true;
        } else {
          this.authenticateUser(false);
        }
      }
    }
  }
 
  authenticateUser(customSwitch: boolean, customSwitchEmail?: string): void {
    let logInEmailAs = '';
    if (customSwitch) {
      logInEmailAs = customSwitchEmail;
    } else {
      logInEmailAs = this.adalService.LoggedInUserEmail;
    }
    this.loadSwitchUserGroup = false;
    this.isBusy = true;
    this.authService.authenticateUser(logInEmailAs, customSwitch).subscribe(() => {
      if (this.userService.currentUser && this.userService.currentUser.email) {
        this.isBusy = false;
        this.router.navigate([appVariables.landingPageUrl]);
      }
    }, (error: any) => {
      if (error) {
        this.isBusy = false;
        this.snackBar.open("Something went wrong. We're working on it and we'll get it fixed soon. Please try again later.", 'Close', {});
      }
    });
  }

  closeAlert() {
    if (this.snackBar._openedSnackBarRef) {
      this.snackBar.dismiss();
    }
  }

  scheduledMaintainance(isMaintainance?: boolean): void {
    if(isMaintainance){
      this.isScheduledMaintainance = true;
      this.loadSwitchUserGroup=false;
      this.isBusy = false;
    }
    else{
      this.authService.checkScheduledMaintainance().subscribe(() =>{}, (error: any) => {
        if (error) {
          console.log("err", error);
          this.isScheduledMaintainance = true;
          this.loadSwitchUserGroup=false;
          this.isBusy = false;
        }
      });
    }
  
}

}
