import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpClientService } from './http-client.service';
import { environment } from '../environments/environment';
export function getAdalConfig() {
    return {
        tenant: environment.adalConfig.tenant,
        clientId: environment.adalConfig.clientId,
        endpoints: environment.adalConfig.endpoints,
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: false,
        cacheLocation: 'sessionStorage'
    };
}
const ɵ0 = (handler) => new TranslateHttpLoader(new HttpClient(handler), './assets/i18n/', '.json'), ɵ1 = (http, router) => new HttpClientService(http, router);
export class AppModule {
}
export { ɵ0, ɵ1 };
