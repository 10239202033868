/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tracking-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./tracking-modal.component";
import * as i10 from "@angular/platform-browser";
var styles_TrackingModalComponent = [i0.styles];
var RenderType_TrackingModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TrackingModalComponent, data: {} });
export { RenderType_TrackingModalComponent as RenderType_TrackingModalComponent };
export function View_TrackingModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "tracking-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "dialog-content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "iframe", [], [[8, "src", 5]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["align", "center"], ["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "button", [["class", "cancel"], ["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(7, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], null, null), i1.ɵdid(8, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(9, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_3 = "primary"; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.Url; _ck(_v, 3, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 8).disabled || null); var currVal_2 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("ATHENA_WEB_UI.BUTTONS_TEXT.CLOSE")); _ck(_v, 9, 0, currVal_4); }); }
export function View_TrackingModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tracking-modal", [], null, null, null, View_TrackingModalComponent_0, RenderType_TrackingModalComponent)), i1.ɵdid(1, 114688, null, 0, i9.TrackingModalComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA, i10.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TrackingModalComponentNgFactory = i1.ɵccf("app-tracking-modal", i9.TrackingModalComponent, View_TrackingModalComponent_Host_0, {}, {}, []);
export { TrackingModalComponentNgFactory as TrackingModalComponentNgFactory };
