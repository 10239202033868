import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-tracking-modal',
  templateUrl: './tracking-modal.component.html',
  styleUrls: ['./tracking-modal.component.scss']
})
export class TrackingModalComponent implements OnInit {

 Url : any;
  constructor(public dialogRef: MatDialogRef<TrackingModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {url: string}, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.Url = this.getSafeResourceURL();
  }

  getSafeResourceURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }

  close() {
    this.dialogRef.close();
  }
}
