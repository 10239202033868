import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { catchError, filter, mergeMap, retry, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  public constructor(private adalService: MsAdalAngular6Service) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // get api url from adal config
    const resource = this.adalService.GetResourceForEndpoint(req.url);
    if (!resource) {
      return next.handle(req);
    }

    // merge the bearer token into the existing headers
    return this.adalService.acquireToken(req.url).pipe(
      mergeMap((token: string) => {
        const authorizedRequest = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`),
        });
        return next.handle(authorizedRequest);
      }));
  }
}


// // merge the bearer token into the existing headers
// return this.adalService.acquireToken(req.url).pipe(
//   mergeMap((token: string) => {
//     const authorizedRequest = req.clone({
//       headers: req.headers.set('Authorization', `Bearer ${token}`),
//     });
//     return next.handle(authorizedRequest).pipe(
//       catchError((error: HttpErrorResponse) => {
//         if (error && error.status === 401) {
//           this.adalService.RenewToken(req.url);
//           return this.adalService.acquireToken(req.url).pipe(
//             mergeMap((token: string) => {
//               const authorizedRequest = req.clone({
//                 headers: req.headers.set('Authorization', `Bearer ${token}`),
//               });
//               return next.handle(authorizedRequest)}));
//         }
//       }));
//   }));
