/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./switch-groups/switch-groups.component.ngfactory";
import * as i2 from "./switch-groups/switch-groups.component";
import * as i3 from "../shared/spinner/spinner.component.ngfactory";
import * as i4 from "../shared/spinner/spinner.component";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./app.component";
import * as i8 from "microsoft-adal-angular6";
import * as i9 from "../core/auth.service";
import * as i10 from "../core/user.service";
import * as i11 from "@angular/material/snack-bar";
import * as i12 from "../shared/application-insights.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-switch-groups", [], null, [[null, "userGroup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("userGroup" === en)) {
        var pd_0 = (_co.authenticateUser($event.isTM, $event.email) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SwitchGroupsComponent_0, i1.RenderType_SwitchGroupsComponent)), i0.ɵdid(1, 114688, null, 0, i2.SwitchGroupsComponent, [], null, { userGroup: "userGroup" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-spinner", [], null, null, null, i3.View_SpinnerComponent_0, i3.RenderType_SpinnerComponent)), i0.ɵdid(1, 114688, null, 0, i4.SpinnerComponent, [], { isbusy: [0, "isbusy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isBusy; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(1, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i0.ɵdid(5, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loadSwitchUserGroup; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isBusy; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.loadSwitchUserGroup; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).clickout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i7.AppComponent, [i8.MsAdalAngular6Service, i9.AuthService, i10.UserService, i5.Router, i5.ActivatedRoute, i11.MatSnackBar, i12.ApplicationInsightsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
