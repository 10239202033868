import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { HttpClientService } from '../http-client.service';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
import * as _ from 'lodash';
import { ApplicationInsightsService } from '../shared/application-insights.service';
import { appVariables } from '../app.constants';
import * as i0 from "@angular/core";
import * as i1 from "microsoft-adal-angular6";
import * as i2 from "../http-client.service";
import * as i3 from "./user.service";
import * as i4 from "../shared/application-insights.service";
export class AuthService {
    constructor(adalService, httpClientService, userService, applicationInsightsService) {
        this.adalService = adalService;
        this.httpClientService = httpClientService;
        this.userService = userService;
        this.applicationInsightsService = applicationInsightsService;
        this.restApiURL = environment.apiHost;
    }
    authenticateUser(loggedInEmail, isImpersonating) {
        this.userService.isAuthenticatingUser = true;
        return this.httpClientService.get(isImpersonating ? `${this.restApiURL}/sales-alignment?imPersonate=${loggedInEmail}` : `${this.restApiURL}/sales-alignment`).pipe(map((response) => {
            this.userService.currentUser = this.covertUserAlignmentFromAPI(loggedInEmail, response);
            this.userService.isAuthenticatingUser = false;
        }), catchError((error) => {
            this.applicationInsightsService.trackExceptions(error);
            return throwError(error);
        }));
    }
    checkScheduledMaintainance() {
        return this.httpClientService.post(`${this.restApiURL}/sales-alignment`).pipe(map(data => {
            return data;
        }, catchError((err) => throwError(err))));
    }
    covertUserAlignmentFromAPI(loggedInEmail, userAlignmentRes) {
        const userDetails = {};
        userDetails.email = loggedInEmail;
        if (sessionStorage.getItem(appVariables.guids.paramGUID) && sessionStorage.getItem(appVariables.guids.searchGUID)) {
            userDetails.isTerritoryManager = false;
        }
        else if (userAlignmentRes.territories && userAlignmentRes.territories.length > 0) {
            userDetails.isTerritoryManager = true;
            userDetails.territories = _.cloneDeep(userAlignmentRes.territories);
        }
        else {
            userDetails.isTerritoryManager = false;
        }
        return userDetails;
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.MsAdalAngular6Service), i0.ɵɵinject(i2.HttpClientService), i0.ɵɵinject(i3.UserService), i0.ɵɵinject(i4.ApplicationInsightsService)); }, token: AuthService, providedIn: "root" });
