import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { HttpClientService } from '../http-client.service';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
import { IUser, IUserAlignmentFromAPI } from './interfaces/app.interface';
import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationInsightsService } from '../shared/application-insights.service';
import { appVariables } from '../app.constants';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private readonly restApiURL: string;

  constructor(private adalService: MsAdalAngular6Service, private httpClientService: HttpClientService,
              private userService: UserService, private applicationInsightsService: ApplicationInsightsService) {
    this.restApiURL = environment.apiHost;
  }

  authenticateUser(loggedInEmail: string, isImpersonating: boolean): Observable<any> {
    this.userService.isAuthenticatingUser = true;

    return this.httpClientService.get<IUserAlignmentFromAPI>(
      isImpersonating ? `${this.restApiURL}/sales-alignment?imPersonate=${loggedInEmail}` : `${this.restApiURL}/sales-alignment` ).pipe(
      map(
        (response: IUserAlignmentFromAPI) => {
          this.userService.currentUser = this.covertUserAlignmentFromAPI(loggedInEmail, response);
          this.userService.isAuthenticatingUser = false;
        }), catchError((error: HttpErrorResponse) => {
        this.applicationInsightsService.trackExceptions(error);
        return throwError(error);
      }));
  }

  public checkScheduledMaintainance(): Observable<any> {
    return this.httpClientService.post<any>(`${this.restApiURL}/sales-alignment`).pipe(
      map(data => {
        return data;
      },
      catchError((err: any) => throwError(err))
    ));
  }
  
  private covertUserAlignmentFromAPI(loggedInEmail: string, userAlignmentRes: IUserAlignmentFromAPI): IUser {
    const userDetails = {} as IUser;
    userDetails.email = loggedInEmail;

    if (sessionStorage.getItem(appVariables.guids.paramGUID) && sessionStorage.getItem(appVariables.guids.searchGUID)) {
      userDetails.isTerritoryManager = false;
    } else if (userAlignmentRes.territories && userAlignmentRes.territories.length > 0) {
      userDetails.isTerritoryManager = true;
      userDetails.territories = _.cloneDeep(userAlignmentRes.territories);
    } else {
      userDetails.isTerritoryManager = false;
    }

    return userDetails;
  }
}
