import { Routes } from '@angular/router';
import { AppComponent } from './components/app.component';
import { AuthenticationGuard } from 'microsoft-adal-angular6';
const ɵ0 = () => import("./feature-modules/orders/orders.module.ngfactory").then(m => m.OrdersModuleNgFactory);
const routes = [
    { path: '', component: AppComponent, pathMatch: 'full', canActivate: [AuthenticationGuard] },
    { path: 'shipto/:shipto', component: AppComponent },
    { path: 'orderStatus', loadChildren: ɵ0, canActivate: [AuthenticationGuard] },
    { path: '**', component: AppComponent, canActivate: [AuthenticationGuard] }
];
export class AppRoutingModule {
}
export { ɵ0 };
