import * as i0 from "@angular/core";
export class UserService {
    constructor() {
        // public currentUserSubject: BehaviorSubject<IUser>;
        // public currentUser: Observable<IUser>;
        this.currentUser = {};
        this.isAuthenticatingUser = false;
        // this.currentUserSubject = new BehaviorSubject<IUser>(null);
        // this.currentUser = this.currentUserSubject.asObservable();
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(); }, token: UserService, providedIn: "root" });
