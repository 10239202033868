<div class="tracking-modal">
  <div mat-dialog-content class="dialog-content">
      <iframe [src]="Url"></iframe>
  </div>
  <div mat-dialog-actions align="center">
    <button mat-raised-button color="primary" class="cancel" (click)="close()" [translate]="">
      {{ 'ATHENA_WEB_UI.BUTTONS_TEXT.CLOSE' | translate }}
    </button>
  </div>
</div>
