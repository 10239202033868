import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material';

@Injectable()
export class SidenavService {
  private notificationSidenav: MatSidenav;
  private filtersSidenav: MatSidenav;


  public setSidenav(sidenav: MatSidenav, isFilters: boolean = true) {
    if (isFilters) {
      this.filtersSidenav = sidenav;
    } else {
      this.notificationSidenav = sidenav;
    }
  }

  public open(isFilters: boolean = true) {
    if(isFilters) {
      return this.filtersSidenav.open();
    } else {
      return this.notificationSidenav.open();
    }
  }


  public close(isFilters: boolean = true) {
    if(isFilters) {
      return this.filtersSidenav.close();
    } else {
      return this.notificationSidenav.close();
    }
  }

  public toggle(isFilters: boolean = true): void {
    if(isFilters) {
      this.filtersSidenav.toggle();
    } else {
      this.notificationSidenav.toggle();
    }
  }
}
